<script setup lang="ts">
import { mdiArrowRight, mdiChevronDown } from "@mdi/js";
import { actionMenuKey, MenuType } from "../../_common/types/menuActionKey";
import { useEventBus } from "@vueuse/core";
enum OverlayName {
  Services = "services",
  Agency = "agency",
}

const { t } = useI18n();
const localeRoute = useLocaleRoute();
const route = useRoute();

const isServicesPanelOpen = ref(false);
const isAgencyPanelOpen = ref(false);

watch(
  () => route.fullPath,
  () => {
    isServicesPanelOpen.value = false;
    isAgencyPanelOpen.value = false;
  },
);
const actionMenuBus = useEventBus(actionMenuKey);
</script>

<template>
  <v-app>
    <navigation-bar>
      <template #desktop-buttons="{ activeOverlay }">
        <HwButton
          :icon="[mdiChevronDown]"
          :iconClass="{
            'icon--rotated': activeOverlay === OverlayName.Services,
          }"
          :title="t('mainMenu.button.services')"
          class="h-100"
          half-padding
          :value="OverlayName.Services"
        />

        <HwButton
          :icon="[mdiChevronDown]"
          :iconClass="{
            'icon--rotated': activeOverlay === OverlayName.Agency,
          }"
          :title="t('mainMenu.button.agency')"
          class="h-100"
          half-padding
          :value="OverlayName.Agency"
        />
      </template>

      <template #desktop-items="{ activeOverlay }">
        <div
          v-if="activeOverlay === OverlayName.Services"
          class="desktop-navigation__grid desktop-navigation__card-row"
        >
          <NavigationMenuCard
            key="softwareDevelopment"
            :card-description="
              t('mainMenu.services.softwareDevelopment.description')
            "
            :card-title="t('mainMenu.services.softwareDevelopment.title')"
            :to="
              localeRoute({
                name: 'software-development',
              })
            "
            class="desktop-navigation__grid__item"
            image-color="#67c6ff"
            image-path="/images/software-dev-logo.svg"
          />

          <NavigationMenuCard
            key="shopwareIntegration"
            :card-classes="['desktop-navigation--shopware-background']"
            :card-description="
              t('mainMenu.services.shopwareIntegration.description')
            "
            :card-title="t('mainMenu.services.shopwareIntegration.title')"
            :external-link="true"
            :image-classes="['hw-circle']"
            :to="
              localeRoute({
                name: 'shopware',
              })
            "
            class="desktop-navigation__grid__item"
            image-color="#1fabff"
            image-path="/images/shopware-logo.svg"
          />

          <NavigationMenuCard
            key="artificialIntelligence"
            :card-classes="[
              'saxony-ai-background',
              'navigation__card--ai-underline',
            ]"
            :card-description="
              t('mainMenu.services.artificialIntelligence.description')
            "
            :card-title="t('mainMenu.services.artificialIntelligence.title')"
            :external-link="true"
            href="https://saxony.ai"
            class="desktop-navigation__grid__item"
            image-color=""
          >
            <template #image>
              <!--render placeholder for spacing-->
              <div class="desktop-navigation__card__image" />
            </template>

            <template #default>
              <HwImage
                object-fit="cover"
                aspect-ratio="1"
                class="desktop-navigation--saxony-ai-image position-absolute pa-lg-20 pa-xl-30 mt-xl-2"
                src="/images/Saxony-ai-Orbits.svg"
                style="max-width: unset"
              />
            </template>
          </NavigationMenuCard>
        </div>

        <div
          v-if="activeOverlay === OverlayName.Agency"
          class="desktop-navigation__grid desktop-navigation__card-row"
        >
          <NavigationMenuCard
            :card-description="t('mainMenu.agency.aboutUs.description')"
            :card-title="t('mainMenu.agency.aboutUs.title')"
            :to="localeRoute({ name: 'about' })"
            class="desktop-navigation__grid__item"
            image-color="#1fabff"
            image-path="/images/logo-portrait.svg"
          />

          <NavigationMenuCard
            :card-description="t('mainMenu.agency.team.description')"
            :card-title="t('mainMenu.agency.team.title')"
            :to="localeRoute({ name: 'employees' })"
            class="desktop-navigation__grid__item"
            image-color="#1574ad"
            image-path="/images/Team.svg"
          />

          <NavigationMenuCard
            :card-description="t('mainMenu.agency.ourPrinciples.description')"
            :card-title="t('mainMenu.agency.ourPrinciples.title')"
            :to="localeRoute({ name: 'about', hash: '#our-principles' })"
            class="desktop-navigation__grid__item"
            image-color="#aee1ff"
            image-path="/images/Technologies.svg"
          />
        </div>
      </template>

      <template #desktop-additional-items>
        <HwButton
          :icon="[mdiArrowRight]"
          :title="t('mainMenu.button.readBlog')"
          :to="localeRoute({ name: 'blog' })"
          color="#FFFFFF"
          full
          underline
          @click="actionMenuBus.emit({ menuType: MenuType.Desktop })"
        />
      </template>

      <template #mobile-navigation>
        <expansion-panel
          :title="t('mainMenu.button.services')"
          position="right"
          simple-chevron
          class="w-100"
          v-model="isServicesPanelOpen"
          :button-underline="false"
        >
          <template #default>
            <NavigationMobileMenuCard
              :to="
                localeRoute({
                  name: 'software-development',
                })
              "
              :card-title="t('mainMenu.services.softwareDevelopment.title')"
              :card-description="
                t('mainMenu.services.softwareDevelopment.description')
              "
              image-path="/images/software-dev-logo.svg"
            />

            <NavigationMobileMenuCard
              :to="
                localeRoute({
                  name: 'shopware',
                })
              "
              :card-title="t('mainMenu.services.shopwareIntegration.title')"
              :card-description="
                t('mainMenu.services.shopwareIntegration.description')
              "
              image-path="/images/shopware-logo.svg"
              class="hw-mt-small"
              :image-classes="['hw-circle']"
              :image-background-classes="[
                'mobile-navigation--shopware-background',
              ]"
              :card-row-classes="['mobile-navigation--shopware-background--xs']"
              external-link
            />

            <NavigationMobileMenuCard
              href="https://saxony.ai"
              :card-title="t('mainMenu.services.artificialIntelligence.title')"
              :card-description="
                t('mainMenu.services.artificialIntelligence.description')
              "
              class="hw-mt-small"
              :card-row-classes="[
                'saxony-ai-background',
                'navigation__card--ai-underline',
              ]"
              external-link
            >
              <template #default>
                <!--only show for display breakpoint xs-->
                <HwImage
                  src="/images/Saxony-ai-Orbits.svg"
                  style="position: absolute; right: 80%"
                  class="saxony-ai-background-vertical--xs d-block d-sm-none"
                />
              </template>

              <template #image>
                <HwImage
                  src="/images/Saxony-ai-Orbits.svg"
                  class="mobile-navigation--saxony-ai-image"
                />

                <div
                  style="
                    padding: var(--spacer);
                    margin: calc(var(--spacer));
                    width: 8rem;
                    height: 8rem;
                  "
                />
              </template>
            </NavigationMobileMenuCard>
          </template>
        </expansion-panel>

        <expansion-panel
          :title="t('mainMenu.button.agency')"
          v-model="isAgencyPanelOpen"
          position="right"
          simple-chevron
          :button-underline="false"
        >
          <template #default>
            <NavigationMobileMenuCard
              :card-title="t('mainMenu.agency.aboutUs.title')"
              :card-description="t('mainMenu.agency.aboutUs.description')"
              :image-path="'/images/logo-portrait.svg'"
              :to="
                localeRoute({
                  name: 'about',
                })
              "
            />

            <NavigationMobileMenuCard
              :card-title="t('mainMenu.agency.team.title')"
              :card-description="t('mainMenu.agency.team.description')"
              :image-path="'/images/Team.svg'"
              image-color="#1574ad"
              :to="
                localeRoute({
                  name: 'employees',
                })
              "
              class="hw-mt-small"
            />

            <NavigationMobileMenuCard
              :card-title="t('mainMenu.agency.ourPrinciples.title')"
              :card-description="t('mainMenu.agency.ourPrinciples.description')"
              :image-path="'/images/Technologies.svg'"
              image-color="#aee1ff"
              :to="
                localeRoute({
                  name: 'about',
                  hash: '#our-principles',
                })
              "
              class="hw-my-small"
            />
          </template>
        </expansion-panel>
        <div class="hw-mr">
          <HwButton
            :title="t('mainMenu.button.readBlogMobile')"
            :to="localeRoute({ name: 'blog' })"
            underline
            :icon="[mdiArrowRight]"
            @click="actionMenuBus.emit({ menuType: MenuType.Mobile })"
          />
        </div>
      </template>
    </navigation-bar>

    <v-main style="--v-layout-top: calc(var(--logo-height-l) + var(--spacer))">
      <v-container class="pb-0 h-100">
        <slot />
      </v-container>
    </v-main>

    <hw-footer />
  </v-app>
</template>

<style scoped>
:deep(.expansion-panel-header) {
  margin-right: var(--spacer-x);
}
</style>
